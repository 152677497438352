import { companyRoles, roleOrder } from '@/constants/ICastAndCrew'
import { formatInTimeZone } from 'date-fns-tz'

export const sortCastAndCrew = (castAndCrew: any[]) => {
	if (castAndCrew.length === 0) return castAndCrew
	return castAndCrew.sort((a, b) => {
		let roleA = roleOrder.indexOf(a.role as companyRoles)
		let roleB = roleOrder.indexOf(b.role as companyRoles)
		if (roleA != roleB) return roleA - roleB // sort based on roles
		return a.name.localeCompare(b.name) // sort alphabetically by name as secondary sorting level
	})
}

export const formatDateRange = (startDate: Date, endDate: Date) => {
	// check if the start and end dates are valid
	if (!startDate || !endDate) {
		return 'bla bla bla'
	}

	const formattedStartDate = formatInTimeZone(
		new Date(startDate),
		'America/Edmonton',
		'MMMM do'
	)

	const formattedEndDate = formatInTimeZone(
		new Date(endDate),
		'America/Edmonton',
		'MMMM do, yyyy'
	)
	return `${formattedStartDate} — ${formattedEndDate}`
}

export const replaceSpacesWithUnderscores = (str: string) => {
	return str.replace(/\s/g, '_')
}

export const replaceUnderscoresWithSpaces = (str: string) => {
	return str.replace(/_/g, ' ')
}

export const roleTotal = (role: string) => {
	switch (role) {
		case companyRoles.Actor:
			return 1000
		case companyRoles.Director:
			return 2000
		case companyRoles.Playwright:
			return 2000
		case companyRoles.CoDirector:
			return 1000
		default:
			return 500
	}
}
