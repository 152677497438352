'use client'
import RichTextViewer from '@/components/RichTextViewer'
import {
	formatDateRange,
	replaceSpacesWithUnderscores
} from '@/lib/helpers/helpers'
import React, { useRef } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Button, Carousel } from 'antd'
import {
	FaCircleChevronLeft as PrevIcon,
	FaCircleChevronRight as NextIcon
} from 'react-icons/fa6'

const HomeSlider = ({ shows }) => {
	const sliderRef = useRef(null)

	const settings = {
		dots: true,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 6000,
		arrows: false
	}

	const previous = () => {
		sliderRef.current.prev()
	}

	const next = () => {
		sliderRef.current.next()
	}

	return (
		<div className='relative'>
			<div className='absolute inset-0 flex items-center justify-between md:px-4'>
				<Button
					type='text'
					shape='circle'
					icon={
						<PrevIcon
							size={46}
							className='text-shadowTan hover:text-shadowBlue md:text-5xl'
						/>
					}
					onClick={previous}
					className='z-10 self-start mt-40 md:m-0 md:self-center'
				/>
				<Button
					type='text'
					shape='circle'
					icon={
						<NextIcon
							size={46}
							className='text-shadowTan hover:text-shadowBlue'
						/>
					}
					onClick={next}
					className='z-10 bg-white right-4 self-start mt-40 md:m-0 md:self-center'
				/>
			</div>
			<Carousel ref={sliderRef} {...settings} className='relative'>
				{shows.map(show => (
					<div
						key={show.id}
						className={`relative w-screen h-[calc(100vh-100px)]`}
					>
						<img
							src={show.image_url}
							alt={show.name}
							className='w-full h-full object-cover'
						/>
						<div className='absolute inset-0 bg-black bg-opacity-50 flex flex-col items-end justify-end text-center text-white p-4'>
							<h1 className='text-4xl font-bold drop-shadow shadow-black'>
								{show.name}
							</h1>
							<h2 className='mt-2'>{show.playwright}</h2>
							<RichTextViewer
								className='text-right force-text-white text-shadowBlue text-shadow-md'
								richText={show.tagline}
							/>
							<h4 className='mt-4'>
								{formatDateRange(
									new Date(show.start_date),
									new Date(show.end_date)
								)}
							</h4>
							<a
								className='mt-4 px-4 py-2 bg-shadowBlue text-white rounded hover:bg-shadowTan'
								href={`/shows/${encodeURIComponent(replaceSpacesWithUnderscores(show.name))}/${show.id}`}
							>
								Learn More
							</a>
						</div>
					</div>
				))}
			</Carousel>
		</div>
	)
}

export default HomeSlider
