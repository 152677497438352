'use client'
import React from 'react'

const RichTextViewer = ({ richText, className = '' }) => {
	return (
		<div>
			<div
				className={className}
				dangerouslySetInnerHTML={{ __html: richText }}
			/>
		</div>
	)
}

export default RichTextViewer
